import {
	createContext,
	createUniqueId,
	mergeProps,
	useContext,
	createEffect,
	splitProps,
	onCleanup,
	createMemo,
	createSignal,
} from 'solid-js';
import { isServer } from 'solid-js/web';
import { twMerge } from '@troon/tailwind-preset/merge';
import { useFocusable } from '../providers/focusable';
import { FormContext, disableAutocomplete } from './form';
import { InputContext, FieldErrors, Field, LabelContext } from './field';
import type { FieldProps } from './field';
import type { JSX, ParentProps } from 'solid-js';

type RadioProps = JSX.InputHTMLAttributes<HTMLInputElement>;

/**
 * @internal
 */
export function RadioInternal(inputProps: RadioProps & { name: string }) {
	const { data } = useContext(FormContext);
	const [input] = useContext(InputContext);
	const focusable = useFocusable<RadioProps>();
	const props = mergeProps(input, focusable, inputProps);

	return (
		<input
			{...props}
			type="radio"
			readonly={data.pending || props.readonly || props.disabled}
			aria-required={props.required}
			{...disableAutocomplete(props)}
		/>
	);
}

export function Radio(inputProps: Omit<RadioProps, 'name'>) {
	const ctx = useContext(RadioGroupContext);
	if (!ctx.name) {
		throw new Error('Radio element must be within a RadioGroup');
	}
	const merged = mergeProps({ name: ctx.name }, inputProps);
	const [, props] = splitProps(merged, ['children']);
	return (
		<Field name={props.name}>
			<div class="group relative flex flex-nowrap items-baseline gap-x-2">
				<RadioInternal
					{...props}
					class="relative size-6 shrink-0 appearance-none self-center rounded-full border border-neutral-500 bg-white outline-none before:absolute before:inset-0 before:m-auto before:size-4 before:rounded-full before:bg-white before:ring-inset before:transition-all before:duration-200 checked:before:bg-brand focus-visible:ring-1 focus-visible:ring-brand-700 active:before:scale-90 active:before:bg-brand-100 disabled:cursor-not-allowed disabled:border-neutral-300"
				/>
				{inputProps.children}
			</div>
		</Field>
	);
}

export function RadioBar(props: ParentProps) {
	return (
		<div class="grid auto-cols-fr grid-flow-col divide-x divide-neutral rounded border border-solid border-neutral bg-white p-1">
			{props.children}
		</div>
	);
}

export function RadioBarButton(inputProps: Omit<RadioProps, 'name'>) {
	const ctx = useContext(RadioGroupContext);
	if (!ctx.name) {
		throw new Error('Radio element must be within a RadioGroup');
	}
	const merged = mergeProps({ name: ctx.name }, inputProps);
	const [, props] = splitProps(merged, ['children']);
	return (
		<Field name={props.name} class="relative px-1 first:ps-0 last:pe-0">
			<RadioInternal {...props} class="peer absolute inset-0 appearance-none outline-none" />
			<div class="inset-0 flex min-h-10 cursor-pointer flex-wrap items-center justify-center rounded py-1 font-semibold text-brand transition-colors duration-200 peer-checked:bg-brand peer-checked:text-white peer-hover:bg-brand-200 peer-checked:peer-hover:bg-brand peer-focus-visible:ring-2 peer-focus-visible:ring-brand peer-disabled:cursor-not-allowed peer-disabled:bg-neutral-200 peer-disabled:text-neutral-600">
				{inputProps.children}
			</div>
		</Field>
	);
}

export const RadioGroupContext = createContext<FieldProps>({ name: '' });

export function RadioGroup(inputProps: FieldProps & { onSelect?: (value: string) => void }) {
	const [other, ctx, props] = splitProps(inputProps, ['children', 'class', 'onSelect'], ['name']);
	return (
		<RadioGroupContext.Provider value={ctx}>
			<Field {...props} name={ctx.name}>
				<Group {...other} />
				<FieldErrors />
			</Field>
		</RadioGroupContext.Provider>
	);
}

/**
 * @internal
 */
export function Group(props: ParentProps & { class?: string; onSelect?: (value: string) => void }) {
	const id = createUniqueId();
	const [group, setGroup] = createSignal<HTMLDivElement>();
	const [, setLabel] = useContext(LabelContext);

	const handleChange = createMemo(() => {
		const onSelect = props.onSelect;
		return (event: Event) => {
			if (onSelect && (event.currentTarget! as HTMLInputElement).checked) {
				onSelect((event.currentTarget! as HTMLInputElement).value);
			}
		};
	});

	createEffect(() => {
		setLabel({ for: undefined, id });
		const groupEl = group();
		if (!isServer && groupEl) {
			groupEl
				.querySelectorAll('input')
				.forEach((el: HTMLInputElement) => el.removeEventListener('change', handleChange()));
			groupEl
				.querySelectorAll('input')
				.forEach((el: HTMLInputElement) => el.addEventListener('change', handleChange()));
		}
	});

	onCleanup(() => {
		const groupEl = group();
		if (!isServer && groupEl) {
			groupEl
				.querySelectorAll('input')
				.forEach((el: HTMLInputElement) => el.removeEventListener('change', handleChange()));
		}
	});

	return (
		<div ref={setGroup} role="radiogroup" aria-labelledby={id} class={twMerge('flex flex-col gap-y-2', props.class)}>
			{props.children}
		</div>
	);
}
