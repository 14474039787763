import { mergeProps, useContext } from 'solid-js';
import { useFocusable } from '../providers/focusable';
import { disableAutocomplete, FormContext } from './form';
import { InputContext } from './field';
import type { JSX } from 'solid-js';

type Props = Omit<JSX.InputHTMLAttributes<HTMLInputElement>, 'aria-required' | 'name'>;

export function FileInput(inputProps: Props) {
	const { data } = useContext(FormContext);
	const [context] = useContext(InputContext);
	const focusable = useFocusable<Props>();
	const props = mergeProps(context, focusable, inputProps);

	return (
		<input
			type="file"
			{...props}
			readonly={data.pending || props.readonly}
			aria-readonly={data.pending || props.readonly}
			aria-required={props.required}
			{...disableAutocomplete(props)}
			class="size-full min-w-min grow-0 cursor-pointer appearance-none rounded border border-solid border-neutral bg-white pe-4 text-neutral-900 outline-none transition-all duration-200 file:me-4 file:cursor-pointer file:rounded-s file:border-0 file:border-e file:border-solid file:border-neutral file:bg-white file:p-4 file:font-semibold file:uppercase file:text-brand file:outline-none file:transition-transform hover:file:bg-neutral-100 focus-visible:ring-1 focus-visible:ring-brand-700 active:file:scale-95 aria-invalid:border-red-600 aria-readonly:bg-neutral-100 aria-readonly:text-neutral-700"
		/>
	);
}
